import {
  fetchResources,
  fetchTranslations,
} from "~/resources/pageResourceHelper";

export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig().public;

  if (
    to.meta.isPublic &&
    config.PUBLIC_URL !== config.BASE_URL &&
    import.meta.client
  ) {
    window.open(config.PUBLIC_URL + to.path, "_self");
    return abortNavigation();
  }

  await fetchTranslations(to);
  await fetchResources(to);
});
